import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                wpPage(
                    title: { eq: "Imprint" }
                ) {
                    imprint_page {
                        imprint
                        statement
                        legalHeader
                    }
                }
            }
        `
    )
    return payload
}
