import React from 'react'
import Layout from '../components/layout'
import ImptintPage from '../components/imprint'

const ImprintPage = () => {
    return (
        <Layout>
            <ImptintPage />
        </Layout>
    )
}

export default ImprintPage