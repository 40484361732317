import React from "react"
import { injectSanitiseHtml } from '../utils'
import useImprintData from './custom-hooks/useImprintData'

import * as privacyStyles from './privacy.module.scss'

const ImprintPage = () => {
    const payload = useImprintData()

    const imprintStyle = {
        padding: "5% 0",
        fontFamily: "Roboto",
        fontSize: "16px",
        lineHeight: "1em",
    }

    const statementStyle = {
        fontFamily: "Roboto",
        fontSize: "16px",
        lineHeight: "20px",
    }

    return (
        <div className={privacyStyles.privacyContainer}>
            <h1 className={privacyStyles.title}>Imprint.</h1>
            {injectSanitiseHtml(
                payload.wpPage.imprint_page.imprint,
                "div",
                imprintStyle
            )}
            <h1 style={{ fontSize: "28px", padding: "0", fontWeight: 'bold', width: '100%' }}>
                {payload.wpPage.imprint_page.legalHeader}
            </h1>
            {injectSanitiseHtml(
                payload.wpPage.imprint_page.statement,
                "div",
                statementStyle
            )}
        </div>
    )
}

export default ImprintPage
